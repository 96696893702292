export const breakpoints = {
    mobile: 480,
    tablet: 768,
    laptop: 1024,
    desktop: 1200,
}

export const sBreakpoints = {
    mobile: `@media (max-width: ${breakpoints.mobile}px)`,
    tablet: `@media (max-width: ${breakpoints.tablet}px)`,
    laptop: `@media (max-width: ${breakpoints.laptop}px)`,
    desktop: `@media (max-width: ${breakpoints.desktop}px)`,
    notMobile: `@media (min-width: ${breakpoints.mobile + 0.51}px)`,
    notTablet: `@media (min-width: ${breakpoints.tablet + 0.51}px)`,
    notLaptop: `@media (min-width: ${breakpoints.laptop + 0.51}px)`,
    notDesktop: `@media (min-width: ${breakpoints.desktop + 0.51}px)`,
}
