import React from 'react'
import { GlobalStyles } from './src/global'
import { CssBaseline } from '@material-ui/core'

export const wrapPageElement = ({ element }) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <GlobalStyles />
            {element}
        </React.Fragment>
    )
}
