// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-estimate-tsx": () => import("./../../../src/pages/estimate.tsx" /* webpackChunkName: "component---src-pages-estimate-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-how-it-works-index-tsx": () => import("./../../../src/pages/how-it-works/index.tsx" /* webpackChunkName: "component---src-pages-how-it-works-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-schedule-event-index-tsx": () => import("./../../../src/pages/schedule-event/index.tsx" /* webpackChunkName: "component---src-pages-schedule-event-index-tsx" */)
}

