import { createTheme } from '@material-ui/core'

export const mainColor = 'rgb(105, 190, 213)'

const theme = createTheme({
    palette: {
        primary: {
            main: mainColor,
        },
    },
    typography: {
        fontFamily: ['Modern Era', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                },
                a: {
                    color: 'unset',
                    textDecoration: 'none',
                },
                body: {
                    flex: 1,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                },
                '#___gatsby': {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                },
                i: {
                    fontStyle: 'normal',
                },
            },
        },
    },
})

export default theme
