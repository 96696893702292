import { createGlobalStyle } from 'styled-components'
import modernEraLight from '../fonts/ModernEra/Modern Era Light.woff2'
import modernEraRegular from '../fonts/ModernEra/Modern Era Regular.woff2'
import modernEraMedium from '../fonts/ModernEra/Modern Era Medium.woff2'
import modernEraBold from '../fonts/ModernEra/Modern Era Bold.woff2'
import React from 'react'
import { sBreakpoints } from '../constants'
const modernEraFontFamily = [
    { fontWeight: 300, url: modernEraLight },
    { fontWeight: 400, url: modernEraRegular },
    { fontWeight: 500, url: modernEraMedium },
    { fontWeight: 700, url: modernEraBold },
]

export const SGlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    body {
        margin: 0;
        --content-padding: 50px;
        --content-width: 1200px;
        ${sBreakpoints.mobile} {
            --content-padding: 16px;
            --content-width: unset;
        }
    }
    html, body {
        background-color: white;
    }
    a {
        color: unset;
        text-decoration: none;
    }
    i {
        font-style: normal;
    }
`

export const GlobalStyles = () => {
    return (
        <React.Fragment>
            <style
                dangerouslySetInnerHTML={{
                    __html: `${modernEraFontFamily
                        .map((fontFace) => {
                            return `
            @font-face {
                font-family: 'Modern Era';
                src: url('${fontFace.url}') format('woff2');
                font-weight: ${fontFace.fontWeight};
                font-style: normal;
            }
        `
                        })
                        .join('')}
                    :root, i {
                    font-family: 'Modern Era', Arial, sans-serif;
                }`,
                }}
            />
            <SGlobalStyles />
        </React.Fragment>
    )
}
